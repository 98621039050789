<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/ubc_alcon_plugin.png"
          alt="ubc alcon logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} UBC - Alcon
      </template>
      <template #subheader>
        Please configure the following in order to install the UBC plugin customized for the client Alcon.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>
    <div>
      Please map the following file headers to a custom field.
      If the field is not mapped, then the column will be empty during exports and will not be processed during imports.
    </div>
    <v-simple-table class="field-table mt-3">
      <thead>
        <tr>
          <th>File Header</th>
          <th>Custom Field</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="mappedFileHeader in mappedFileHeaders"
          :key="mappedFileHeader"
        >
          <td>{{ mappedFileHeader }}</td>
          <td>
            <CustomDropdown
              v-model="fieldMapping[mappedFileHeader]"
              :items="customFieldOptions"
              autocomplete
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <hr class="my-7">

    <div>
      <CustomTextInput
        v-model="clientId"
        header="Client ID"
        required
      />
    </div>
    <div>
      <CustomTextInput
        v-model="clientSecret"
        header="Client Secret"
        type="password"
        required
      />
    </div>
    <div>
      <CustomDropdown
        v-model="segments"
        :items="allSegments"
        header="Segments to send to UBC"
        item-value="id"
        item-text="name"
        required
        multiple
        autocomplete
        hint="The contact must belong to one of these segments for the data to be sent to UBC via API."
      />
    </div>

    <hr class="mt-7">


    <validation-observer ref="autoExportConfigs">
      <AutomatedExportConfigurations
        :automated-export-configurations="exportConfigurations"
        :show-export-delta-files-checkbox="false"
        :show-create-empty-files-checkbox="false"
        :default-export-config="defaultExportConfig"
        :export-type-options="[{id: exportType, text: 'UBC Alcon Export'}]"
        @add-configuration="type => addExportConfiguration({type}, 0)"
        @clone-configuration="cloneExportConfiguration"
        @delete-configuration="deleteExportConfiguration"
        @update-configuration="updateExportValue"
      />
    </validation-observer>

    <hr class="mt-7">

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="plugin"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(null, null, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedExportConfigurations from "@/views/Plugins/components/AutomatedExportConfigurations.vue";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";
import { v4 as uuidv4 } from "uuid";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import importExportMixin from "@/utils/import-export-mixin";

export default {
  name: "AddPluginUBCAlcon",
  metaInfo: {
    title: 'Install UBC'
  },
  components: {
    AutomatedExportConfigurations,
    AutomatedImportConfigurations,
    PageHeader,
    CustomDropdown,
    CustomTextInput,
    DeactivateConfirmDialog,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    fieldMapping: {},
    plugin: null,
    mappedFileHeaders: [
      'UBC ID',
      'Product',
      'Medication Format',
      'Savings Card',
      'Survey Participation',
    ],
    clientId: null,
    clientSecret: null,
    allSegments: [],
    segments: [],
    fileExtensionOptions: [
      { id: 'csv', text: 'Comma-Separated Values (.csv)' },
    ],
    importType: 'ubc_alcon',
    exportType: 'ubc_alcon_contact',
    defaultExportConfig: {
      exportType: 'ubc_alcon_contact',
      // The vendor specifically requested delta files and empty files
      exportDeltaFiles: true,
      canCreateEmptyFile: true,
    },
  }),
  computed: {
    customFieldOptions() {
      const customFields = this.$store.getters['user/customFields']?.contact;

      if (!customFields) {
        return [];
      }

      return [
        {
          label: 'Automatically create a custom field',
          value: null,
        },
        ...customFields.map((field) => {
          return {
            label: field.name,
            value: field.fieldName,
          };
        })
      ];
    },
  },
  async created() {
    await this.loadSegments();

    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadSegments() {
      let segments = (await this.$rest.segment.get_collection({
        ignorePagination: true
      })).data

      this.allSegments = segments.items.map(segment => ({
        ...segment,
        name: segment.isArchived ? segment.name + " [Archived]" : segment.name
      }));
    },
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.plugin = {...resp.data};

      let credentials = resp.data.pluginConfiguration?.credentials;
      if (!credentials || Array.isArray(credentials)) {
        credentials = {};
      }

      this.clientId = credentials?.clientId ?? null;
      this.clientSecret = credentials?.clientSecret ?? null;

      this.segments = resp.data.pluginConfiguration?.segments ?? [];
      this.fieldMapping = resp.data.pluginConfiguration?.fieldMapping ?? {};
      if (Array.isArray(this.fieldMapping)) {
        this.fieldMapping = {};
      }

      const configMapFunc = config => {
        const configCredentials = credentials[config.key] ?? null;

        if(config?.frequency?.time) {
          const [hour, minute] = config.frequency.time.split(":");
          if (+hour >= 12) {
            config.frequency.ampm = "PM"
            config.frequency.hour = +hour > 12 ? +hour - 12 : 12;
          }
          else {
            config.frequency.ampm = "AM"
            config.frequency.hour = (+hour === 0) ? 12 : hour;
          }
          config.frequency.minute = minute;

          // Remove this config in case the frequency changes
          delete config.frequency.nextRunDate;
        }

        return {
          ...config,
          credentials: configCredentials,
        };
      };

      if (resp.data.pluginConfiguration?.exportConfigurations) {
        const exportConfigurations = resp.data.pluginConfiguration?.exportConfigurations.map(configMapFunc);
        exportConfigurations.forEach(config => {
          this.addExportConfiguration(config);
        });
      }

      if (resp.data.pluginConfiguration?.importConfigurations) {
        const importConfigurations = resp.data.pluginConfiguration?.importConfigurations.map(configMapFunc);
        importConfigurations.forEach(config => {
          this.addImportConfiguration(config);
        });
      }
    },
    addExportConfiguration(existingConfig) {
      this.exportConfigurations.push(this.generateExportConfiguration({
        ...existingConfig,
        ...this.defaultExportConfig,
      }));
    },
    cloneExportConfiguration(index) {
      const existingConfig = this.exportConfigurations[index] ?? null;
      this.addExportConfiguration({
        ...existingConfig,
        name: 'Clone of ' + existingConfig?.name || '',
        key: null,
        history: null,
        lastExportDate: null,
      });
    },
    addImportConfiguration(existingConfig) {
      this.importConfigurations.push({
        connectionType: existingConfig?.connectionType || null,
        directory: existingConfig?.directory || '/',
        archiveDirectory: existingConfig?.archiveDirectory || '',
        fileExtension: existingConfig?.fileExtension || this.fileExtensionOptions[0]?.id,
        credentials: {
          host: existingConfig?.credentials?.host || null,
          username: existingConfig?.credentials?.username || null,
          password: existingConfig?.credentials?.password || null,
          port: existingConfig?.credentials?.port || null,
          privateKey: existingConfig?.credentials?.privateKey || null,
          aws_region: existingConfig?.credentials?.aws_region || null,
          aws_access_id: existingConfig?.credentials?.aws_access_id || null,
          aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
          aws_bucket: existingConfig?.credentials?.aws_bucket || null,
        },
        frequency: existingConfig?.frequency || {
          type: null,
          ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
          hour: (new Date()).getHours(),
          minute: '00',
          timezone: 'America/New_York',
          date: [],
        },
        importType: this.importType,
        importOptions: existingConfig?.importOptions ? {...existingConfig?.importOptions} : { choice: null },
        name: existingConfig?.name || '',
        notificationRecipients: existingConfig?.notificationRecipients || '',
        key: existingConfig?.key || uuidv4(),
        isShowing: false,
        history: existingConfig?.history ? [...existingConfig?.history] : [],
        isExisting: !!existingConfig?.key,
      });
    },
    getFormattedPluginConfiguration(expectedExportConfig, expectedImportConfig) {
      // Removing old field mapping to avoid issues
      // We can probably remove this in the future
      delete this.fieldMapping['Text Messages'];
      delete this.fieldMapping['Email Messages'];
      delete this.fieldMapping['Nurse Program'];

      const pluginConfiguration = {
        exportConfigurations: [],
        importConfigurations: [],
        segments: this.segments,
        fieldMapping: this.fieldMapping,
        credentials: {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
        },
      };

      for (const config of (expectedExportConfig || this.exportConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.exportConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }
      for (const config of (expectedImportConfig || this.importConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.importConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }

      return pluginConfiguration;
    },
    async installPlugin(expectedExportConfig, expectedImportConfig, returnResponse) {
      const pluginConfiguration = this.getFormattedPluginConfiguration(expectedExportConfig, expectedImportConfig);

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            // Reload the selected account in case custom fields got created
            const selectedAccount = this.$store.getters["user/account"];
            this.$store.dispatch(
              'user/selectAccount',
              selectedAccount.accountId,
              selectedAccount.logo
            );

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
.field-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
